import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Uses`}</h1>
    <h2>{`Editor + Terminal`}</h2>
    <ul>
      <li parentName="ul">{`Visual Studio Code`}</li>
      <li parentName="ul">{`GitHub Dark Theme`}</li>
      <li parentName="ul">{`Dank Mono font`}</li>
      <li parentName="ul">{`Terminal`}</li>
    </ul>
    <h2>{`Desktop Apps`}</h2>
    <ul>
      <li parentName="ul">{`Brave browser for building, debugging, dev tools, and watching Call of Duty videos on YouTube`}</li>
      <li parentName="ul">{`Figma for exploring my design interests`}</li>
      <li parentName="ul">{`Postico for connecting to Postgres`}</li>
      <li parentName="ul">{`Robo 3T for managing Mongo databases`}</li>
      <li parentName="ul">{`Insomnia for interacting with APIs`}</li>
      <li parentName="ul">{`Kap for recording GIFs for documentation`}</li>
      <li parentName="ul">{`Spectacle for window management`}</li>
    </ul>
    <h2>{`Recording`}</h2>
    <ul>
      <li parentName="ul">{`Screenflow`}</li>
      <li parentName="ul">{`Shure smb7b`}</li>
      <li parentName="ul">{`Rode boom arm`}</li>
      <li parentName="ul">{`Focusrite`}</li>
      <li parentName="ul">{`Cloud lifter`}</li>
      <li parentName="ul">{`RDM for managing screen resolutions`}</li>
    </ul>
    <h2>{`Desk Setup`}</h2>
    <ul>
      <li parentName="ul">{`16" MacBook Pro`}</li>
      <li parentName="ul">{`2x LG Ultrafine 5K monitor`}</li>
      <li parentName="ul">{`AirPods + Bose QC35s`}</li>
      <li parentName="ul">{`Keychron K6`}</li>
      <li parentName="ul">{`Logitech MX3`}</li>
    </ul>
    <h2>{`Gaming Setup`}</h2>
    <ul>
      <li parentName="ul">{`PlayStation 5`}</li>
      <li parentName="ul">{`32" 4K TV`}</li>
      <li parentName="ul">{`Razor headphones`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      